<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: v2Dashboard.vue
Description:This component is the combination of three components   dashboardTab ,pondInfoTab,pondAlertsTab
-->
<template>
  <layout-pondlogs class="v2-dasboard">
    <template slot="layout-pondlogs-scrollable-main">
      <portal to="destination">
        <div class="portal-container">
          <er-top-route-nav
            :items="filterMenuItemsByPermissions"
          ></er-top-route-nav>
        </div>
      </portal>
      <component :is="computedComponentName" :tabData="menuItems[computedComponentName]"></component>
      <!-- <new-release-popup></new-release-popup> -->
    </template>
  </layout-pondlogs>
</template>

<script>
import { mapGetters } from "vuex";
import { handleStoreRegistration } from "@/utils/storeRegisterHandlerUtils";
import redirectsMixin from "@/mixins/redirectsMixin";
import newReleasePopup from "../../components/newRelease/newReleasePopup.vue";
import {
  permissionsToStringMap as permissions,
  isValidTabName,
  getUserSelectedTabBasedOnPermissions
} from "@/middleware/pageAccessManager";
const dashboardTab = () =>
  import(
    /* webpackChunkName: "dashboardTab" */ "@/components/v2Dashboard/dashboard/dashboardTab"
  );
const pondInfoTab = () =>
  import(
    /* webpackChunkName: "pondInfoTab" */ "@/components/v2Dashboard/pond-info/pondInfoTab"
  );
const pondAlertsTab = () =>
  import(
    /* webpackChunkName: "pondAlertsTab" */ "@/components/v2Dashboard/pond-alerts/pondAlertsTab"
  );
const tabToPermission = {
  dashboardTab: {
    name: "dashboardTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [permissions.MANAGE_OVERVIEW]
    PERMISSIONS: {
      VIEW: [
        permissions.MANAGE_OVERVIEW
      ],
      UPDATE: [permissions.MANAGE_OVERVIEW]
    }
  },
  pondInfoTab: {
    name: "pondInfoTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [
    //   permissions.MANAGE_OVERVIEW,
    //   permissions.MANAGE_CULTURE,
    //   permissions.MANAGE_ABW,
    //   permissions.MANAGE_HARVEST
    // ]
    PERMISSIONS: {
      VIEW: [
        permissions.MANAGE_OVERVIEW,
        permissions.MANAGE_CULTURE,
        permissions.MANAGE_ABW,
        permissions.MANAGE_HARVEST
      ],
      UPDATE: [permissions.MANAGE_OVERVIEW]
    }
  },
  pondAlertsTab: {
    name: "pondAlertsTab",
    keyToValidateAccess: "PERMISSIONS",
    // PERMISSIONS: [
    //   permissions.MANAGE_OVERVIEW,
    //   permissions.MANAGE_CULTURE,
    //   permissions.MANAGE_DEVICES
    // ]
    PERMISSIONS: {
      VIEW: [
        permissions.MANAGE_OVERVIEW,
        permissions.MANAGE_CULTURE,
        permissions.MANAGE_DEVICES
      ],
      UPDATE: [permissions.MANAGE_OVERVIEW]
    }
  }
};

export default {
  name: "DashboardTab",
  mixins: [redirectsMixin],
  components: {
    dashboardTab,
    pondInfoTab,
    pondAlertsTab,
    newReleasePopup
  },
  computed: {
    ...mapGetters("user", {
      getViewPermissions: "getViewPermissions",
      getCurrUserLocation: "getCurrUserLocation",
      getPreferredUnits: "getPreferredUnits"
    }),
    menuItems: function() {
      return {
        dashboardTab: {
          label: this.$t("Comn_dashboard"),
          ...tabToPermission.dashboardTab
        },
        pondInfoTab: {
          label: `${this.$t("Comn_ponds")} Info`,
          ...tabToPermission.pondInfoTab
        },
        pondAlertsTab: {
          label: `${this.$t("Comn_ponds")} ${this.$t("Comn_alerts")}`,
          ...tabToPermission.pondAlertsTab
        }
      };
    },
    filterMenuItemsByPermissions: function() {
      console.log(
        "Setting it to false",
        this.$gblUAMFilterItemsUserCanView(this.menuItems)
      );
      // localStorage.setItem("AlertHistory", false);
      return this.$gblUAMFilterItemsUserCanView(this.menuItems);
    },
    computedComponentName: function() {
      return this.$route.query.tab;
    }
  },
  beforeRouteEnter(to, from, next) {
    handleStoreRegistration(to, from);
    next((vm) => {
      const component = vm;
      const viewPermissions =
        component.$store.getters["user/getViewPermissions"];
      const userType = component.$store.getters["user/getCurrUserType"];
      const tabName = component.$route.query.tab;
      if (
        !isValidTabName(tabName, tabToPermission, viewPermissions, userType)
      ) {
        component.rdm__navigateToPathWithLocationId({
          path: "/user/dashboard",
          query: {
            tab: getUserSelectedTabBasedOnPermissions(
              tabToPermission,
              viewPermissions,
              userType,
              component.$store.getters["user/getPreferredUnits"]
                .default_dashboard_tab
            )
          }
        });
      }
      console.log("Setting it to false");
      // localStorage.setItem("AlertHistory", false);
    });
  },
  methods: {}
};
</script>

<style lang="scss"></style>
